import { FC, useEffect, useRef } from 'react';
import { useClassName } from '../../hooks/use-class-name';
import SpriteIcon from '../shared/sprite-icon';
import { useTranslation } from '../../hooks/use-translation';
import { useBuildDownloadUrl } from '../../hooks/use-download-url-builder';
import lottie from 'lottie-web';
import downLoadAnimation from './animations/download-icon.json';
import Utils from '../../services/utils';
import { useAnalytics } from '../../hooks/use-analytics';
import useOS from '../../hooks/use-os';

// -----------------------------------------------------------------------------
interface Props {
  isOpen: boolean;
  onClickToggleOpen: () => void;
  onClickClose: () => void;
  currentApp: {appId: string, appName: string};
}

// -----------------------------------------------------------------------------
export const DownloadModal: FC<Props> = ({
  isOpen,
  onClickToggleOpen,
  onClickClose,
  currentApp

}): JSX.Element => {
  const analytics = useAnalytics();
  const OS = useOS();
  const animIconContainer = useRef<HTMLDivElement>(null);
  const downloadUrl = useBuildDownloadUrl({
    channel: 'web_dl_btn',
    name: currentApp.appName,
    extensionId: currentApp.appId,
    utmContent: 'new-light'
  });

  const handleClickDownload = (event: React.MouseEvent<HTMLElement>): void => {
    Utils.InstallerDownloadOverwrite(event, currentApp.appId);

    analytics.track('appstore_download_clicked', {
      'appId': currentApp.appId,
      'appName': currentApp.appName,
      'component': 'Download Modal',
      'platform': 'Web',
    });

    analytics.gtagPush({
      event: 'app_download_cta',
      app_name: currentApp.appName,
      app_id: currentApp.appId,
      app_os: OS,
    });

  };

  const { t } = useTranslation();

  const className = useClassName({
    'modal-is-open': isOpen
  }, 'download-modal');

  useEffect(() =>{
    const timeout = setTimeout(() => {
      lottie.loadAnimation({
        container: animIconContainer.current, // Required
        animationData: downLoadAnimation, // Required
        renderer: 'svg', // Required
        loop: true, // Optional
        autoplay: true, // Optional
        name: 'download-icon' // Name for future reference. Optional.
      });
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={className}>
      <div className='download-modal-main'>
        <button
          className='clean-icon-btn close-modal'
          onClick={onClickToggleOpen}
        >
          <SpriteIcon icon='closeSmall' />
        </button>

        <div className='download-modal-content'>
          <div>
            <div id='download-icon' className='anim-icon' ref={animIconContainer} />
            <h2>{t('downloadStart')}</h2>
            <p>
              {t('ifItDoesnt')}
              <a
                onClick={handleClickDownload}
                href={downloadUrl}
                download={t('appInstaller')}
              >
                {t('dlHere')}
              </a>
            </p>
          </div>

          <div>
            <p className='small' dangerouslySetInnerHTML={{
              __html: t('dlSupport')
            }}
            />
          </div>
        </div>
      </div>
      <div className='bg' onClick={onClickClose} />
    </div>
  );
};
