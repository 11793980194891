'use client';

import { useEffect, useState } from 'react';
import { DownloadModal } from './download-modal';
import useBus from '../../hooks/use-bus';
import { AppBusEventType } from '../../interfaces/bus-events';

export const DownloadModalWrapper = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentApp, setCurrentApp] = useState({ appName: null, appId: null });

  useBus(AppBusEventType.App.ShowDownloadModal, event => {
    const { data } = event;
    setCurrentApp({
      appId: data.currentApp.appId,
      appName: data.currentApp.appName,
    });

    setIsOpen(data.isOpen);
  });

  useEffect(() => {
    const onScroll = (): void => {
      if (!isOpen) {
        return;
      }

      setIsOpen(false);
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isOpen]);

  const handleToggleOpen = (): void => {
    setIsOpen(cur => !cur);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  return (
    <DownloadModal
      isOpen={isOpen}
      onClickToggleOpen={handleToggleOpen}
      onClickClose={handleClose}
      currentApp={currentApp}
    />
  );
};
